import React from 'react';

function App() {
    return (
        <div className='w-screen min-h-screen relative px-6 py-8 md:pb-24'>
            <div className='flex items-center font-black'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#f43f5e'
                    className='w-24 h-24 md:w-32 md:h-auto'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                    />
                </svg>

                <span className='text-5xl'>Toolbox</span>
            </div>

            <div className='md:grid gap-4 grid-cols-3 mt-6'>
                <figure className='md:hidden bg-gray-100 rounded-xl p-8 mb-6 md:mb-0'>
                    <svg
                        viewBox='0 0 511.998 511.998'
                        className='w-16 h-16 md:w-32 md:h-auto mx-auto'
                    >
                        <path
                            fill='#60a5fa'
                            d='M505.743,6.249c-6.08-6.101-15.211-7.893-23.168-4.672l-469.333,192c-8.768,3.605-14.123,12.544-13.12,21.973
			c0.981,9.429,8.064,17.067,17.387,18.773l220.139,40.021l40.043,220.139c1.685,9.323,9.323,16.405,18.752,17.408
			c0.747,0.064,1.493,0.107,2.219,0.107c8.576,0,16.448-5.184,19.755-13.269l192-469.333
			C513.658,21.459,511.823,12.329,505.743,6.249z'
                        />
                    </svg>

                    <div className='pt-6 md:p-8 text-center md:text-left space-y-4'>
                        <blockquote>
                            <h2 className='text-xl font-bold'>Naver</h2>
                            <p className='text-lg'>Navigate thru QRCode</p>
                        </blockquote>
                        <a
                            href='https://naver.toolbox.primatech.id'
                            className='block text-center mt-6 bg-blue-400 w-full rounded text-white py-4'
                        >
                            Open
                        </a>
                    </div>
                </figure>

                <figure className='hidden md:block bg-gray-100 rounded-xl p-8 mb-6 md:mb-0'>
                    <svg
                        className='w-16 h-16 md:w-32 md:h-auto mx-auto'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='#4fd1c5'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z'
                        />
                    </svg>

                    <div className='pt-6 md:p-8 text-center md:text-left space-y-4'>
                        <blockquote>
                            <h2 className='text-xl font-bold'>Ncoder</h2>
                            <p className='text-lg'>Encode text to QRCode</p>
                        </blockquote>
                        <a
                            href='https://ncoder.toolbox.primatech.id'
                            className='block text-center mt-6 bg-teal-400 w-full rounded text-white py-4'
                        >
                            Open
                        </a>
                    </div>
                </figure>

                <figure className='md:hidden bg-gray-100 rounded-xl p-8 mb-6 md:mb-0'>
                    <svg
                        className='w-16 h-16 md:w-32 md:h-auto mx-auto'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 64 64'
                        width='512'
                        height='512'
                    >
                        <linearGradient
                            id='a'
                            gradientUnits='userSpaceOnUse'
                            x1='-33.129'
                            x2='49.145'
                            y1='45.129'
                            y2='-37.145'
                        >
                            <stop offset='0' stopColor='#003f8a' />
                            <stop offset='.518' stopColor='#00d7df' />
                            <stop offset='1' stopColor='#006df0' />
                        </linearGradient>
                        <linearGradient
                            id='b'
                            x1='-7.129'
                            x2='75.145'
                            xlinkHref='#a'
                            y1='71.129'
                            y2='-11.145'
                        />
                        <linearGradient
                            id='c'
                            x1='18.871'
                            x2='101.145'
                            xlinkHref='#a'
                            y1='97.129'
                            y2='14.855'
                        />
                        <path
                            d='m1 22a1 1 0 0 0 1-1v-19h19a1 1 0 0 0 0-2h-20a1 1 0 0 0 -1 1v20a1 1 0 0 0 1 1z'
                            fill='url(#a)'
                        />
                        <path
                            d='m21 62h-19v-19a1 1 0 0 0 -2 0v20a1 1 0 0 0 1 1h20a1 1 0 0 0 0-2z'
                            fill='url(#b)'
                        />
                        <path
                            d='m63 42a1 1 0 0 0 -1 1v19h-19a1 1 0 0 0 0 2h20a1 1 0 0 0 1-1v-20a1 1 0 0 0 -1-1z'
                            fill='url(#c)'
                        />
                        <g fill='url(#b)'>
                            <path d='m63 0h-20a1 1 0 0 0 0 2h19v19a1 1 0 0 0 2 0v-20a1 1 0 0 0 -1-1z' />
                            <path d='m41 31h-8v-8a1 1 0 0 0 -2 0v8h-8a1 1 0 0 0 0 2h8v8a1 1 0 0 0 2 0v-8h8a1 1 0 0 0 0-2z' />
                        </g>
                    </svg>

                    <div className='pt-6 md:p-8 text-center md:text-left space-y-4'>
                        <blockquote>
                            <h2 className='text-xl font-bold'>Zumer</h2>
                            <p className='text-lg'>Zoom QRCode</p>
                        </blockquote>
                        <a
                            href='https://zumer.toolbox.primatech.id'
                            className='block text-center mt-6 bg-blue-400 w-full rounded text-white py-4'
                        >
                            Open
                        </a>
                    </div>
                </figure>
            </div>
        </div>
    );
}

export default App;
